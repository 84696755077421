import React from 'react';

const HexagramExplanation = () => {
  return (
    <div className="hexagram-explanation">
      <h2>About the Hexagrams</h2>
      <p>
        This interactive display features all 64 hexagrams, which can be navigated using the arrow keys on a desktop or by clicking on the left or right side of the hexagram on mobile devices. Each hexagram is composed of a bottom trigram and a top trigram, representing different elements and principles.
      </p>
      <p>
        The energy flow within the hexagrams is from bottom to top, and this is reflected in the binary representation of each trigram. In this binary system, Yin lines are represented by 0, and Yang lines are represented by 1. This allows for a deeper understanding of the balance and dynamics within each hexagram.
      </p>
    </div>
  );
};

export default HexagramExplanation;