import React from 'react';

function Footer() {
  return (
    <footer className="app-footer">
      Developed by <a href="https://iterative.day" target="_blank" rel="noopener noreferrer">iterative.day</a>
    </footer>
  );
}

export default Footer;