import React, { useEffect } from 'react';
import './App.css';
import HexagramsViewer from './components/HexagramsViewer'; // Adjust the path as necessary
import Footer from './components/Footer';
import ReactGA from 'react-ga4';
import HexagramExplanation from './components/HexagramExplanation'; // Import the new component



function App() {
  useEffect(() => {
    ReactGA.initialize('G-PXT6C2GCCY'); // Replace 'G-PXT6C2GCCY' with your GA4 Measurement ID
    ReactGA.send('pageview'); // For single-page apps, this only needs to be called once
  }, []);

  return (
    <div className="app">      
        <h1 className="title">Trigram Symphony</h1>                
        <HexagramsViewer />
        <HexagramExplanation />      
        <Footer /> {/* Include the Footer component */}
    </div>
  );
}

export default App;