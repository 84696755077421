import React, { useState, useEffect, useCallback } from 'react';
import { hexagramData, trigrams, trigramChineseMapping } from '../constants/hexagrams';
import ReactGA from 'react-ga4'; // Import react-ga4
import './HexagramsViewer.css';

const YangLine = () => {
  return <div className="yang-line"></div>;
};

const YinLine = () => {
  return (
    <div className="yin-line">
      <div className="yin-part"></div>
      <div className="yin-part"></div>
    </div>
  );
};

const binaryToLines = (binary) => {
  if (!binary) {
    return [];
  }
  // Reverse the binary string to match the bottom-to-top reading order
  const reversedBinary = binary.split('').reverse().join('');
  return reversedBinary.split('').map((bit, index) => bit === '1' ? <YangLine key={index} /> : <YinLine key={index} />);
  // return binary.split('').map((bit, index) => bit === '1' ? <YangLine key={index} /> : <YinLine key={index} />);
};

const HexagramsViewer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hexagrams, setHexagrams] = useState([]);

  useEffect(() => {
    setHexagrams(hexagramData);
    setIsLoading(false);
  }, []);

  const handleKeyPress = useCallback((event) => {
    let newIndex;
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowRight':
        newIndex = (currentIndex + 1) % hexagrams.length;
        break;
      case 'ArrowDown':
      case 'ArrowLeft':
        newIndex = (currentIndex - 1 + hexagrams.length) % hexagrams.length;
        break;
      default:
        return; // Early return if the key is not one of the arrows
    }

    setCurrentIndex(newIndex);

    // Track the hexagram change event with Google Analytics
    ReactGA.event({
      category: 'Hexagram',
      action: 'Change',
      label: `Hexagram Index: ${newIndex}`,
    });
  }, [currentIndex, hexagrams.length]); // Dependencies

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (isLoading) {
    return <div>Loading hexagrams...</div>;
  }

  const currentHexagram = hexagrams[currentIndex];
  const topTrigramBinary = trigrams[currentHexagram.topTrigram];
  const bottomTrigramBinary = trigrams[currentHexagram.bottomTrigram];
  const topTrigramChinese = trigramChineseMapping[currentHexagram.topTrigram];
  const bottomTrigramChinese = trigramChineseMapping[currentHexagram.bottomTrigram];

  console.log('Top Trigram Binary:', topTrigramBinary);
  console.log('Bottom Trigram Binary:', bottomTrigramBinary);


  const topLines = binaryToLines(topTrigramBinary);
  const bottomLines = binaryToLines(bottomTrigramBinary);  
 
  const nextHexagram = () => {
    const newIndex = (currentIndex + 1) % hexagrams.length;
    setCurrentIndex(newIndex);
    // Log the change with Google Analytics
    ReactGA.event({
      category: 'Hexagram',
      action: 'Change',
      label: `Hexagram Index: ${newIndex}`,
    });
  };

  const previousHexagram = () => {
    const newIndex = (currentIndex - 1 + hexagrams.length) % hexagrams.length;
    setCurrentIndex(newIndex);
    // Log the change with Google Analytics
    ReactGA.event({
      category: 'Hexagram',
      action: 'Change',
      label: `Hexagram Index: ${newIndex}`,
    });
  };

  const handleHexagramClick = (event) => {
    const hexagramElement = event.currentTarget;
    const clickX = event.clientX; // Get the horizontal coordinate of the click
    const elementWidth = hexagramElement.offsetWidth;
    const elementLeftEdge = hexagramElement.getBoundingClientRect().left;

    // Determine if the click was on the left or right half of the element
    if (clickX - elementLeftEdge < elementWidth / 2) {
      previousHexagram(); // Click was on the left side
    } else {
      nextHexagram(); // Click was on the right side
    }
  };

  return (
    <div className="hexagram" onClick={handleHexagramClick}>
      <div className="trigram-container">{topLines}</div>
      <div className="trigram-container">{bottomLines}</div>

      <h2>{currentHexagram.name} ({currentHexagram.number})</h2>

      <p>{currentHexagram.topTrigram} {topTrigramChinese} ({topTrigramBinary})</p>
      <p>{currentHexagram.bottomTrigram} {bottomTrigramChinese} ({bottomTrigramBinary})</p>

      <p>{currentHexagram.meaning}</p>
    </div>
  );
};

export default HexagramsViewer;