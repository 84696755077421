//from https://www.jamesdekorne.com/GBCh/GBCh.htm
export const hexagramMapping = {
  1: '111111', // ䷀ (Qian) The Dynamic
  2: '000000', // ䷁ (Kun) The Magnetic
  3: '100010', // ䷂ (Zhun) Difficulty
  4: '010001', // ䷃ (Meng) Inexperience 
  5: '111010', // ䷄ (Xu) Waiting 
  6: '010111', // ䷅ (Song) Stress
  7: '010000', // ䷆ (Shi) Discipline 
  8: '000010', // ䷇ (Bi) Holding Together
  9: '111011', // ䷈ (Xiao Chu) Passive Restraint
  10: '110111', // ䷉ (Lu) Cautious Advance 
  11: '111000', // ䷊ (Tai) Harmony 
  12: '000111', // ䷋ (Pi) Divorcement 
  13: '101111', // ䷌ (Tong Ren) Union of Forces 
  14: '111101', // ䷍ (Da You) Wealth 
  15: '001000', // ䷎ (Qian) Temperance 
  16: '000100', // ䷏ (Yu) Enthusiasm/Self-Deception/Repose
  17: '100110', // ䷐ (Sui)  Following 
  18: '011001', // ䷑ (Gu) Repair
  19: '110000', // ䷒ (Lin) Approach 
  20: '000011', // ䷓ (Guan) Contemplation 
  21: '100101', // ䷔ (Shi He) Discernment 
  22: '101001', // ䷕ (Bi) Persona 
  23: '000001', // ䷖ (Bo) Disintegration 
  24: '100000', // ䷗ (Fu) Return 
  25: '100111', // ䷘ (Wu Wang) Innocence 
  26: '111001', // ䷙ (Da Chu) Controlled Power 
  27: '100001', // ䷚ (Yi) Nourishment
  28: '011110', // ䷛ (Da Guo) Critical Mass
  29: '010010', // ䷜ (Kan) Danger
  30: '101101', // ䷝ (Li) Clarity
  31: '001110', // ䷞ (Xian) Initiative (Influence)
  32: '011100', // ䷟ (Heng) Consistency
  33: '001111', // ䷠ (Dun) Retreat 
  34: '111100', // ䷡ (Da Zhuang) Great Power
  35: '000101', // ䷢ (Jin) Advance of Consciousness
  36: '101000', // ䷣ (Ming Yi) Clouded Perception
  37: '101011', // ䷤ (Jia Ren) The Family
  38: '110101', // ䷥ (Kui) Mutual Alienation
  39: '001010', // ䷦ (Jian) Impasse
  40: '010100', // ䷧ (Xie) Liberation
  41: '110001', // ䷨ (Sun) Compensating Sacrifice 
  42: '100011', // ䷩ (Yi) Increase 
  43: '111110', // ䷪ (Guai) Resoluteness 
  44: '011111', // ䷫ (Gou) Temptation 
  45: '000110', // ䷬ (Cui) Gathering Together (Contraction)
  46: '011000', // ䷭ (Sheng) Pushing Upward
  47: '010110', // ䷮ (Kun) Oppression 
  48: '011010', // ䷯ (Jing) The Well
  49: '101110', // ䷰ (Ge) Metamorphosis 
  50: '011101', // ䷱ (Ding) The Sacrificial Vessel
  51: '100100', // ䷲ (Zhen) Shock/Thunder
  52: '001001', // ䷳ (Gen) Keeping Still
  53: '001011', // ䷴ (Jian) Gradual Progress-
  54: '110100', // ䷵ (Gui Mei) Propriety/Making-Do
  55: '101100', // ䷶ (Feng) Abundance (Expansion of Awareness) 
  56: '001101', // ䷷ (Lu) Transition 
  57: '011011', // ䷸ (Xun) Penetration
  58: '110110', // ䷹ (Dui) Joy (Self-indulgence)
  59: '010011', // ䷺ (Huan) Expansion (Dispersion) 
  60: '110010', // ䷻ (Jie) Restrictive Regulations 
  61: '110011', // ䷼ (Zhong Fu) Inner Truth
  62: '001100', // ䷽ (Xiao Guo) Small Powers
  63: '101010', // ䷾ (Ji Ji) Completion 
  64: '010101', // ䷿ (Wei Ji) Unfinished Business
};


export const hexagramData = [
  { number: 1, name: 'Qian 乾', topTrigram: 'Heaven', bottomTrigram: 'Heaven', meaning: 'The Creative' },
  { number: 2, name: 'Kun 坤', topTrigram: 'Earth', bottomTrigram: 'Earth', meaning: 'The Receptive' },
  { number: 3, name: 'Zhun 屯', topTrigram: 'Water', bottomTrigram: 'Thunder', meaning: 'Difficulty at the Beginning' },
  { number: 4, name: 'Meng 蒙', topTrigram: 'Mountain', bottomTrigram: 'Water', meaning: 'Youthful Folly' },
  { number: 5, name: 'Xu 需', topTrigram: 'Water', bottomTrigram: 'Heaven', meaning: 'Waiting' },
  { number: 6, name: 'Song 訟', topTrigram: 'Heaven', bottomTrigram: 'Water', meaning: 'Conflict' },
  { number: 7, name: 'Shi 師', topTrigram: 'Earth', bottomTrigram: 'Water', meaning: 'The Army' },
  { number: 8, name: 'Bi 比', topTrigram: 'Water', bottomTrigram: 'Earth', meaning: 'Holding Together' },
  { number: 9, name: 'Xiao Chu 小畜', topTrigram: 'Wind', bottomTrigram: 'Heaven', meaning: 'Small Accumulating' },
  { number: 10, name: 'Lu 履', topTrigram: 'Heaven', bottomTrigram: 'Lake', meaning: 'Treading' },
  { number: 11, name: 'Tai 泰', topTrigram: 'Earth', bottomTrigram: 'Heaven', meaning: 'Peace' },
  { number: 12, name: 'Pi 否', topTrigram: 'Heaven', bottomTrigram: 'Earth', meaning: 'Standstill' },
  { number: 13, name: 'Tong Ren 同人', topTrigram: 'Fire', bottomTrigram: 'Heaven', meaning: 'Fellowship with Men' },
  { number: 14, name: 'Da You 大有', topTrigram: 'Heaven', bottomTrigram: 'Fire', meaning: 'Possession in Great Measure' },
  { number: 15, name: 'Qian 謙', topTrigram: 'Earth', bottomTrigram: 'Mountain', meaning: 'Modesty' },
  { number: 16, name: 'Yu 豫', topTrigram: 'Thunder', bottomTrigram: 'Earth', meaning: 'Enthusiasm' },
  { number: 17, name: 'Sui 隨', topTrigram: 'Lake', bottomTrigram: 'Thunder', meaning: 'Following' },
  { number: 18, name: 'Gu 蠱', topTrigram: 'Mountain', bottomTrigram: 'Wind', meaning: 'Work on What Has Been Spoiled' },
  { number: 19, name: 'Lin 臨', topTrigram: 'Earth', bottomTrigram: 'Lake', meaning: 'Approach' },
  { number: 20, name: 'Guan 觀', topTrigram: 'Wind', bottomTrigram: 'Earth', meaning: 'Contemplation' },
  { number: 21, name: 'Shi He 噬嗑', topTrigram: 'Fire', bottomTrigram: 'Thunder', meaning: 'Biting Through' },
  { number: 22, name: 'Bi 賁', topTrigram: 'Mountain', bottomTrigram: 'Fire', meaning: 'Grace' },
  { number: 23, name: 'Bo 剝', topTrigram: 'Mountain', bottomTrigram: 'Earth', meaning: 'Splitting Apart' },
  { number: 24, name: 'Fu 復', topTrigram: 'Earth', bottomTrigram: 'Thunder', meaning: 'Return' },
  { number: 25, name: 'Wu Wang 無妄', topTrigram: 'Heaven', bottomTrigram: 'Thunder', meaning: 'Innocence' },
  { number: 26, name: 'Da Chu 大畜', topTrigram: 'Mountain', bottomTrigram: 'Heaven', meaning: 'The Taming Power of the Great' },
  { number: 27, name: 'Yi 頤', topTrigram: 'Thunder', bottomTrigram: 'Mountain', meaning: 'Nourishment' },
  { number: 28, name: 'Da Guo 大過', topTrigram: 'Lake', bottomTrigram: 'Wind', meaning: 'Preponderance of the Great' },
  { number: 29, name: 'Kan 坎', topTrigram: 'Water', bottomTrigram: 'Water', meaning: 'The Abysmal' },
  { number: 30, name: 'Li 離', topTrigram: 'Fire', bottomTrigram: 'Fire', meaning: 'The Clinging' },
  { number: 31, name: 'Xian 咸', topTrigram: 'Lake', bottomTrigram: 'Mountain', meaning: 'Influence' },
  { number: 32, name: 'Heng 恆', topTrigram: 'Thunder', bottomTrigram: 'Wind', meaning: 'Duration' },
  { number: 33, name: 'Dun 遯', topTrigram: 'Heaven', bottomTrigram: 'Mountain', meaning: 'Retreat' },
  { number: 34, name: 'Da Zhuang 大壯', topTrigram: 'Thunder', bottomTrigram: 'Heaven', meaning: 'The Power of the Great' },
  { number: 35, name: 'Jin 晉', topTrigram: 'Fire', bottomTrigram: 'Earth', meaning: 'Progress' },
  { number: 36, name: 'Ming Yi 明夷', topTrigram: 'Earth', bottomTrigram: 'Fire', meaning: 'Darkening of the Light' },
  { number: 37, name: 'Jia Ren 家人', topTrigram: 'Wind', bottomTrigram: 'Fire', meaning: 'The Family' },
  { number: 38, name: 'Kui 睽', topTrigram: 'Fire', bottomTrigram: 'Lake', meaning: 'Opposition' },
  { number: 39, name: 'Jian 蹇', topTrigram: 'Mountain', bottomTrigram: 'Water', meaning: 'Obstruction' },
  { number: 40, name: 'Xie 解', topTrigram: 'Thunder', bottomTrigram: 'Water', meaning: 'Deliverance' },
  { number: 41, name: 'Sun 損', topTrigram: 'Lake', bottomTrigram: 'Mountain', meaning: 'Decrease' },
  { number: 42, name: 'Yi 益', topTrigram: 'Thunder', bottomTrigram: 'Wind', meaning: 'Increase' },
  { number: 43, name: 'Guai 夬', topTrigram: 'Heaven', bottomTrigram: 'Lake', meaning: 'Breakthrough' },
  { number: 44, name: 'Gou 姤', topTrigram: 'Wind', bottomTrigram: 'Heaven', meaning: 'Coming to Meet' },
  { number: 45, name: 'Cui 萃', topTrigram: 'Lake', bottomTrigram: 'Earth', meaning: 'Gathering Together' },
  { number: 46, name: 'Sheng 升', topTrigram: 'Earth', bottomTrigram: 'Wind', meaning: 'Pushing Upward' },
  { number: 47, name: 'Kun 困', topTrigram: 'Lake', bottomTrigram: 'Water', meaning: 'Oppression' },
  { number: 48, name: 'Jing 井', topTrigram: 'Water', bottomTrigram: 'Wood', meaning: 'The Well' },
  { number: 49, name: 'Ge 革', topTrigram: 'Lake', bottomTrigram: 'Fire', meaning: 'Revolution' },
  { number: 50, name: 'Ding 鼎', topTrigram: 'Fire', bottomTrigram: 'Wind', meaning: 'The Caldron' },
  { number: 51, name: 'Zhen 震', topTrigram: 'Thunder', bottomTrigram: 'Thunder', meaning: 'The Arousing' },
  { number: 52, name: 'Gen 艮', topTrigram: 'Mountain', bottomTrigram: 'Mountain', meaning: 'Keeping Still' },
  { number: 53, name: 'Jian 漸', topTrigram: 'Wind', bottomTrigram: 'Mountain', meaning: 'Development' },
  { number: 54, name: 'Gui Mei 歸妹', topTrigram: 'Thunder', bottomTrigram: 'Lake', meaning: 'The Marrying Maiden' },
  { number: 55, name: 'Feng 豐', topTrigram: 'Thunder', bottomTrigram: 'Fire', meaning: 'Abundance' },
  { number: 56, name: 'Lu 旅', topTrigram: 'Fire', bottomTrigram: 'Mountain', meaning: 'The Wanderer' },
  { number: 57, name: 'Xun 巽', topTrigram: 'Wind', bottomTrigram: 'Wind', meaning: 'The Gentle' },
  { number: 58, name: 'Dui 兌', topTrigram: 'Lake', bottomTrigram: 'Lake', meaning: 'The Joyous' },
  { number: 59, name: 'Huan 渙', topTrigram: 'Wind', bottomTrigram: 'Water', meaning: 'Dispersion' },
  { number: 60, name: 'Jie 節', topTrigram: 'Water', bottomTrigram: 'Lake', meaning: 'Limitation' },
  { number: 61, name: 'Zhong Fu 中孚', topTrigram: 'Lake', bottomTrigram: 'Wind', meaning: 'Inner Truth' },
  { number: 62, name: 'Xiao Guo 小過', topTrigram: 'Thunder', bottomTrigram: 'Mountain', meaning: 'Preponderance of the Small' },
  { number: 63, name: 'Ji Ji 既濟', topTrigram: 'Water', bottomTrigram: 'Fire', meaning: 'After Completion' },
  { number: 64, name: 'Wei Ji 未濟', topTrigram: 'Fire', bottomTrigram: 'Water', meaning: 'Before Completion' },
];

// yin = broken, yang = solid line
// The Fuxi sequence, also known as the "Early Heaven Sequence" 
// or the "Primal Arrangement," 
export const trigrams_named = {
  qian: {
    lines: ['yang', 'yang', 'yang'],
    element: 'Heaven',
  },
  kun: {
    lines: ['yin', 'yin', 'yin'],
    element: 'Earth',
  },
  zhen: {
    lines: ['yang', 'yang', 'yin'],
    element: 'Thunder',
  },
  kan: {
    lines: ['yin', 'yang', 'yin'],
    element: 'Water',
  },
  gen: {
    lines: ['yang', 'yin', 'yin'],
    element: 'Mountain',
  },
  xun: {
    lines: ['yin', 'yin', 'yang'],
    element: 'Wind',
  },
  li: {
    lines: ['yang', 'yin', 'yang'],
    element: 'Fire',
  },
  dui: {
    lines: ['yin', 'yang', 'yang'],
    element: 'Lake',
  },
};

//The trigrams are typically arranged in a circular sequence known as the 
//"King Wen sequence" or the "Later Heaven Sequence." 
//This arrangement is attributed to King Wen, one of the legendary founders
// of the Zhou dynasty in ancient China.
export const trigrams = {
  Heaven: '111', // ☰ Heaven qian
  Earth: '000', // ☷ Earth kun
  Water: '010', // ☵ Water kan
  Fire: '101', // ☲ Fire li
  Mountain: '001', // ☶ Mountain gen
  Wind: '011', // ☴ Wind xun
  Lake: '110', // ☱ Lake dui
  Thunder: '100', // ☳ Thunder zhen
};

export const trigramChineseMapping = {
  Heaven: '乾',
  Earth: '坤',
  Water: '坎',
  Fire: '離',
  Mountain: '艮',
  Wind: '巽',
  Lake: '兌',
  Thunder: '震',
};